<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.code" placeholder="请输入完整的编号"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="HS编码	">
              <a-input v-model="queryParam.hs_code" placeholder="请输入完整的HS编码"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="类别">
              <a-select
                show-search
                :show-arrow="false"
                :filter-option="false"
                allow-clear
                v-model="tag_value"
                placeholder="请输入类别"
                :default-active-first-option="false"
                @search="handleTagSearch"
                @change="handleTagSearchChange"
              >
                <a-select-option v-for="d in dataSource_tag" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="是否在售">
              <a-switch v-model="queryParam.is_active"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 80px; max-width: 90px">
        {{ text }}
      </div>
      <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <a-row
          :gutter="24"
          :style="{ marginBottom: '12px' }">
          <a-col :span="24" :style="{ marginBottom: '12px' }">
            <a-col :span="4">
              英文名：{{ record.en_name || '-' }}
            </a-col>
            <a-col :span="4">
              原产地：{{ record.original_place || '-' }}
            </a-col>
            <a-col :span="4">
              增值税率 ：{{ record.value_added_tax_rate || '-' }}
            </a-col>
            <a-col :span="4">
              消费税率 ：{{ record.consumption_tax_rate || '-' }}
            </a-col>
            <a-col :span="4">
              关税税率 ：{{ record.tariff_rate || '-' }}
            </a-col>
          </a-col>
        </a-row>
      </p>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handlebatch(record)" >保税商品</a>
          <a-divider type="vertical"/>
          <a @click="handleaddGoods(record)" > 展示货物</a>
          <a-divider type="vertical"/>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="showDrawer(record)">类别设置</a>
          <a-divider type="vertical" />
        </template>
        <a-dropdown>
          <a class="ant-dropdown-link">
            更多 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handlefujian(record)" >附件</a>
            </a-menu-item>
            <a-menu-item
              v-if="record.is_active"
            >
              <a-popconfirm
                title="确定停止售卖么？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelet(record)"
              >
                <a>停售</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <exhibiton-form
      ref="createPayModal"
      v-if="payvisible"
      :visible="payvisible"
      :loading="payconfirmLoading"
      :model="paymdl"
      @cancel="handlePayCancel"
      @ok="handlePayOk"
    />
    <a-drawer
      title="类别设置"
      placement="right"
      :closable="false"
      :visible="DrawerVisible"
      @close="onCloseDrawer"
      :after-visible-change="afterVisibleChange"
    >
      <p :style="{ marginRight: '8px' }">请选择货物类别:</p>
      <template v-for="tag in tags">
        <a-checkable-tag
          :key="tag.id"
          :checked="selectedTags.indexOf(tag.name) > -1"
          @change="checked => handleTagChange(tag, checked)"
        >
          {{ tag.name }}
        </a-checkable-tag>
      </template>
    </a-drawer>

  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import ExhibitonForm from '../modules/CreateExhibitonGoodsForm'
import {
  bonded_goods_category_list,
  bonded_goods_create,
  bonded_goods_delete,
  bonded_goods_list,
  bonded_goods_category_bonded_goods_create,
  bonded_goods_category_bonded_goods_delete, bonded_goods_category_bonded_goods_list
} from '@/api/bonded_goods'
import { exhibition_goods_create } from '@/api/exhibition_goods'
import { Drawer, Tag } from 'ant-design-vue'
let Base64 = require('js-base64').Base64
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    Drawer,
    Tag,
    ExhibitonForm
  },
  data () {
    return {
      tag_value: '',
      dataSource_tag: [],
      tags: [],
      allTags: [],
      selectedTags: [],
      DrawerVisible: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          width: '200px',
          ellipsis: true,
          dataIndex: 'name'
        },
        {
          title: '商品编号',
          dataIndex: 'code',
          ellipsis: true
        },
        {
          title: 'HS编号',
          width: '150px',
          dataIndex: 'hs_code',
          ellipsis: true
        },
        {
          title: '总数量',
          dataIndex: 'total_count',
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '展示数量',
          dataIndex: 'outside_count',
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          ellipsis: true
        },
        {
          title: '销售状态',
          dataIndex: 'is_active',
          customRender: (text) => text ? '在售' : '停售',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '400px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return bonded_goods_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.getTags()
  },
  methods: {
    handleTagSearch (value) {
      console.log(value, '98989')
      bonded_goods_category_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        console.log(result)
        this.dataSource_tag = !value ? [] : result
      })
    },
    handleTagSearchChange (value) {
      console.log(value, '1111111')
      this.queryParam.category_ids = value
      this.tag_value = value
    },
    time (dates, dateStrings) {
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push('/bonded/goods/' + record.id)
    },
    handleaddGoods (record) {
      this.payvisible = true
      this.paymdl = record
    },
    handlefujian (record) {
      this.$router.push('/bonded_goods/attachment/' + record.id)
    },
    showDrawer (record) {
      this.DrawerVisible = true
      bonded_goods_category_list().then((res) => {
        console.log(res.data, '我是quanbu类别')
        const allTags = res.data.entries || []
        bonded_goods_category_bonded_goods_list({ bonded_goods_id: record.id }).then((res) => {
          this.tags = allTags
          const result = res.data.entries
          this.selectedTags = result.map((item) => item.category) || []
          this.current_corporation_id = record.id
        })
      })
    },
    onCloseDrawer () {
      this.DrawerVisible = false
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    getTags () {
      bonded_goods_category_list().then((res) => {
        console.log(res.data, '我是quanbu类别')
        this.allTags = res.data || []
      })
    },
    handleTagChange (tag, checked) {
      console.log(tag, checked)
      const { selectedTags } = this
      const nextSelectedTags = checked
        ? [...selectedTags, tag.name]
        : selectedTags.filter(t => t !== tag.name)
      console.log('你选的类别: ', nextSelectedTags)
      if (checked) {
        bonded_goods_category_bonded_goods_create({ bonded_goods_id: this.current_corporation_id, category_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      } else {
        bonded_goods_category_bonded_goods_delete({ bonded_goods_id: this.current_corporation_id, category_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      }
    },
    handlebatch (record) {
      this.$router.push({ path: '/bonded_goods/batch/' + record.id, query: { record: Base64.encode(JSON.stringify(record)) } })
    },
    handleDelet (record) {
      bonded_goods_delete(record.id).then((res) => {
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.corporation_id = this.$Dictionaries.corporation_id
          // 新增
          bonded_goods_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
          .catch((err) => {
            console.log(err)
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handlePayOk () {
      const form = this.$refs.createPayModal.form
      this.payconfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.tag_price = Math.round(values.tag_price * 100)
          values.sales_price = Math.round(values.sales_price * 100)
          const param = Object.assign({}, values, { bonded_goods_id: values.id })
          // 新增
          exhibition_goods_create(param).then(res => {
            this.payvisible = false
            this.payconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
          .catch((err) => {
            console.log(err)
            this.payvisible = false
            this.payconfirmLoading = false
          })
        } else {
          this.payconfirmLoading = false
        }
      })
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
