<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="英文名" >
              <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="商品编号" >
              <a-input v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="原产地" >
              <a-input v-decorator="['original_place', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="HS编号" >
              <a-input v-decorator="['hs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="排序" >
              <a-input placeholder="请输入数字，数字小展示在前面" v-decorator="['serial', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="增值税率" >
              <a-input-number
                :max="0.99"
                :min="0.00"
                :step="0.01"
                v-decorator="['value_added_tax_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">

            <a-form-item label="消费税率" >
              <a-input-number
                :max="0.99"
                :min="0.00"
                :step="0.01"
                v-decorator="['consumption_tax_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="关税税率" >
              <a-input-number
                :max="0.99"
                :min="0.00"
                :step="0.01"
                v-decorator="['tariff_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注" >
              <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="单位" >
              <a-input v-decorator="['unit', {rules: [{required: true, message: '必填项，请填写信息'}, {max:4, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="保质期开关" >
              <a-switch
                @change="handleLifeChange"
                v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="保质期" v-show="is_show">
              <a-input v-decorator="['shelf_life', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="保质期单位" v-show="is_show">
              <a-select
                v-decorator="[
                  'shelf_life_unit',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.SelfLifeUnit">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['id', 'number', 'corporation_id', 'total_payment']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      is_show: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleLifeChange (value) {
      console.log(`selected ${value}`)
      this.is_show = !!value
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
